<template>
  
</template>

<script>
export default {
  created () {
    // this.$router.push('/hfPage?id=HFSaga&l=jp')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-saga-1-week-free-pass/jp', '_self')
  }

}
</script>

<style>

</style>